import { 
  mainnet,
  goerli,
  sepolia,
  bsc,
  bscTestnet,
  gnosis,
  polygon,
  avalanche,
  dogechain,
  arbitrum
} from 'viem/chains'

const EndpointsViem = {
  "1": mainnet.rpcUrls.default.http[0],
  "5": goerli.rpcUrls.default.http[0],
  "11155111": sepolia.rpcUrls.default.http[0],
  "56": bsc.rpcUrls.default.http[0],
  "97": bscTestnet.rpcUrls.default.http[0],
  "100": gnosis.rpcUrls.default.http[0],
  "137": polygon.rpcUrls.default.http[0],
  "43114": avalanche.rpcUrls.default.http[0],
  "2000": dogechain.rpcUrls.default.http[0],
  "42161": arbitrum.rpcUrls.default.http[0],
  
  "1337": "http://127.0.0.1:8545",
}

export default EndpointsViem