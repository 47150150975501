import { 
  mainnet,
  goerli,
  sepolia,
  bsc,
  bscTestnet,
  gnosis,
  polygon,
  avalanche,
  dogechain,
  arbitrum,
  base
} from 'viem/chains'

const Endpoints = {

  Mainnet: mainnet.rpcUrls.default.http[0],
  Goerli: goerli.rpcUrls.default.http[0],
  Sepolia: sepolia.rpcUrls.default.http[0],
  BSC_MAINNET: bsc.rpcUrls.default.http[0],
  BSC_TESTNET: bscTestnet.rpcUrls.default.http[0],
  xDai: gnosis.rpcUrls.default.http[0],
  Matic: polygon.rpcUrls.default.http[0],
  AVAX: avalanche.rpcUrls.default.http[0],
  DogeChain: dogechain.rpcUrls.default.http[0],
  ArbitrumOne: arbitrum.rpcUrls.default.http[0],
  Base: base.rpcUrls.default.http[0],
  
  Ganache: "http://127.0.0.1:8545",
}

export default Endpoints